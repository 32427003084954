import React, { createRef, useState, useEffect, useRef } from "react";
import {
	Typography,
	Divider,
	theme,
	Form,
	Radio,
	Input,
	Button,
	message,
	Avatar,
	List,
	Space
} from "antd";
import { LikeOutlined } from "@ant-design/icons";
import Wrap from "./../Wrap";

const layout = {
	labelCol: {
		span: 3
	},
	wrapperCol: {
		span: 14
	}
};

const ForumViewNM: React.FC = (props) => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	const editorRef = useRef(null);
	var formRef = React.createRef();
	const { topicid } = props.match.params;

	const [data, setData] = useState({
		id: 0,
		topic: "",
		content: [],
		updtime: "",
		category: "",
		manager: 0
	});
	const [loading, setLoading] = useState(true);
	//const [userData, setUserData] = useState(new Map());

	function loadTopic(cap) {
		fetch(
			"//bbs-api.hywiki.org/v2" + "/forum/topic-getlist.php?id=" + topicid
		)
			.then((res) => res.json())
			.catch((error) => message.error("加载话题失败: " + error, 2.5))
			.then((response) => {
				/*
				let tempuserData = new Map();
				response.content.forEach((e) => {
					fetch("//bbs-api.hywiki.org/v2" + "/user-getinfo.php?id=" + e.u)
			        .then((res) => res.json())
			        .catch((error) => message.error("加载用户信息失败: " + error, 2.5))
			        .then((resp) => {
			            e.uiname = resp.name;
			        })
				})
			            console.log(response)*/
				setLoading(false);
				setData(response);
				//setUserData(tempuserData);
				if (cap) message.success("话题加载成功", 2.5);
			});
	}

	useEffect(() => {
		loadTopic(true);
	}, [topicid]);
	const IconText = ({ icon, text }) => (
		<Space>
			{React.createElement(icon)}
			{text}
		</Space>
	);
	const finishTable = (event) => {
		if (editorRef.current) {
			console.log(editorRef.current.getContent());
		}

		formRef.current
			.validateFields()
			.then((value) => {
				let submitForm = new FormData();
				submitForm.append("tid", topicid);
				submitForm.append("id", localStorage.getItem("hw_user_id"));
				submitForm.append("pwd", localStorage.getItem("hw_user_pwd"));
				submitForm.append("content", value["content"]);

				fetch("//bbs-api.hywiki.org/v2" + "/forum/topic-continue.php", {
					method: "POST",
					body: submitForm
				})
					.then((res) => res.text())
					.catch((error) => message.error("发布失败: " + error, 2.5))
					.then(function (response) {
						if (response.indexOf("err") === -1) {
							//document.querySelector("#continueTextarea").value = "";
							message.success(response, 2.5);
							loadTopic(false);
						} else {
							message.error(response);
						}
					});
			})
			.catch((err) => {
				alert("数据校验失败: " + err);
			});
	};

	const eToC = (e) => {
		if (e == "school") return "校园生活区";
		if (e == "technology") return "科技数码区";
		if (e == "site") return "站务区";
		if (e == "water") return "灌水区";
		if (e == "hywiki") return "维基讨论";
		if (e == "others") return "其他";
		return e;
	};

	return (
		<Wrap BrLv1="论坛" BrLv2={"话题: " + data.topic}>
			<Typography>
				<Title level={2}>{data.topic}</Title>
				<Paragraph>
					{"ID: " +
						data.id +
						" / 分类: " +
						eToC(data.category) +
						" / 创建时间: " +
						(data.content.length > 0 ? data.content[0].t : "") +
						" / 更新时间: " +
						data.updtime +
						" / 楼层数: " +
						data.content.length}
				</Paragraph>
				<Divider />
				<List
					itemLayout="vertical"
					size="large"
					loading={loading}
					pagination={{
						onChange: (page) => {
							console.log(page);
						},
						pageSize: 20
					}}
					dataSource={data.content}
					footer={
						<div>
							<b>话题:</b> {data.topic} / <b>楼数:</b>{" "}
							{data.content.length} / <b>状态:</b> 开放
						</div>
					}
					style={{ whiteSpace: "pre-wrap" }}
					renderItem={(item) => (
						<List.Item
							key={item.t}
							actions={[
								<IconText
									icon={LikeOutlined}
									text="点赞 (开发中)"
									key="list-vertical-like-o"
								/>
							]}
						>
							<List.Item.Meta
								avatar={
									<Avatar
										src={
											"https://sdn.geekzu.org/avatar/" +
											item.ue5
										}
									/>
								}
								title={
									<a href={"#/user/i/" + item.u}>{item.un}</a>
								}
								description={item.t}
							/>
							{item.c}
						</List.Item>
					)}
				/>
				<Form
					{...layout}
					layout="horizontal"
					ref={formRef}
					onFinish={finishTable}
				>
					<Form.Item name="content" label="跟帖: ">
						<Input.TextArea
							allowClear
							id="continueTextarea"
							rows={5}
						/>
					</Form.Item>
					<Form.Item
						wrapperCol={{
							...layout.wrapperCol,
							offset: 3
						}}
					>
						<Button type="primary" htmlType="submit">
							跟帖
						</Button>
					</Form.Item>
				</Form>
			</Typography>
		</Wrap>
	);
};

export default ForumViewNM;
