import React, { createRef, useState, useEffect } from "react";
import {
	Typography,
	Divider,
	theme,
	List,
	Skeleton,
	Avatar,
	Button
} from "antd";
import Wrap from "./../Wrap";

const ForumList: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;

	const [initLoading, setInitLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [list, setList] = useState([]);
	const [offset, setOffset] = useState(0);
	useEffect(() => {
		fetch("//bbs-api.hywiki.org/v2" + "/forum/topic-getlist.php?offset=0")
			.then((res) => res.json())
			.then((res) => {
				setInitLoading(false);
				setData(res.data);
				setList(res.data);
				setOffset(res.offset);
			});
	}, []);
	const onLoadMore = () => {
		setLoading(true);
		setList(
			data.concat(
				[...new Array(1)].map(() => ({
					loading: true,
					topic: {},
					content: {},
					id: {},
					category: {}
				}))
			)
		);
		fetch(
			"//bbs-api.hywiki.org/v2" +
				"/forum/topic-getlist.php?offset=" +
				offset
		)
			.then((res) => res.json())
			.then((res) => {
				const newData = data.concat(res.data);
				setData(newData);
				setList(newData);
				setLoading(false);
				setOffset(res.offset);
				console.log(newData);
				// Resetting window's offsetTop so:to display react-virtualized demo underfloor.
				// In real scene, you can using public method of react-virtualized:
				// https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
				window.dispatchEvent(new Event("resize"));
			});
	};
	const loadMore =
		!initLoading && !loading ? (
			<div
				style={{
					textAlign: "center",
					marginTop: 12,
					height: 32,
					lineHeight: "32px"
				}}
			>
				<Button onClick={onLoadMore}>加载更多</Button>
			</div>
		) : null;
	const briefHandler = (str) => {
		try {
			return JSON.parse(str)[0].c;
		} catch (e) {
			return str;
		}
	};
	const eToC = (e) => {
		if (e == "school") return "校园生活区";
		if (e == "tech") return "科技数码区";
		if (e == "site") return "站务区";
		if (e == "water") return "灌水区";
		if (e == "hywiki") return "维基讨论";
		if (e == "others") return "其他";
		return e;
	};

	return (
		<Wrap BrLv1="论坛" BrLv2="话题列表">
			<Typography>
				<Title level={2}>话题列表</Title>
				<Paragraph>当前已加载 {list.length} 个话题</Paragraph>
			</Typography>
			<Divider />
			<List
				className="topic-list"
				loading={initLoading}
				itemLayout="horizontal"
				loadMore={loadMore}
				dataSource={list}
				responsive="lg"
				renderItem={(item) => (
					<List.Item
						actions={[
							<a
								key="list-loadmore-edit"
								href={"#/forum/view/" + item.id}
							>
								打开
							</a>,
							<a
								key="list-loadmore-more"
								href={"#/forum/view-classic/" + item.id}
							>
								纯文本
							</a>
						]}
					>
						<Skeleton title={false} loading={item.loading} active>
							<List.Item.Meta
								title={
									<a href={"#/forum/view/" + item.id}>
										{item.topic}
									</a>
								}
								description={briefHandler(item.brief)}
							/>
							<div style={{ whiteSpace: "pre-wrap" }}>
								{"共" +
									item.clen +
									"楼 / 分类: " +
									eToC(item.category) +
									" \n " +
									item.updtime}
							</div>
						</Skeleton>
					</List.Item>
				)}
			/>
		</Wrap>
	);
};

export default ForumList;
