import React, { createRef, useState, useEffect } from "react";
import {
	Typography,
	Divider,
	theme,
	Form,
	Radio,
	Input,
	Button,
	message
} from "antd";
import Wrap from "./../Wrap";

const layout = {
	labelCol: {
		span: 3
	},
	wrapperCol: {
		span: 14
	}
};

const UserSet: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	var formRef = React.createRef();
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const [realnamed, setRealnamed] = useState(0);
	const [info, setInfo] = useState({
		id: 0,
		name: "",
		realname: "",
		email: "",
		description: "",
		birthday: "YYYY-mm-dd",
		register: "2022-12-15 12:00",
		action: [],
		settings: {background:"blank"}
	});
	var updateFlag = 0;
	
	const finishTable = (event) => {
		formRef.current
			.validateFields()
			.then((value) => {
				let submitForm = new FormData();
				console.log(value)
				submitForm.append("id", localStorage.getItem("hw_user_id"));
				submitForm.append("pwd", localStorage.getItem("hw_user_pwd"));
				//submitForm.append("topic", value["topic"]);
				submitForm.append("background", value["background"]);
				submitForm.append("realname", value["realname"]);
				//submitForm.append("content", value["content"]);

				fetch("//bbs-api.hywiki.org/v2" + "/user-settings.php", {
					method: "POST",
					body: submitForm
				})
					.then((res) => res.text())
					.catch((error) => message.error("设置失败: " + error, 2.5))
					.then(function (response) {
						if (response.indexOf("err") === -1) {
							message.success(response, 2.5);
						} else {
							message.error(response, 2.5);
						}
						updateFlag = updateFlag + 1;
					});
			})
			.catch((err) => {
				alert("数据校验失败: " + err);
			});
	};
	const clearEmpty = (event) => {
		let submitForm = new FormData();
		submitForm.append("id", localStorage.getItem("hw_user_id"));
		submitForm.append("pwd", localStorage.getItem("hw_user_pwd"));
		fetch("//bbs-api.hywiki.org/v2" + "/forum/topic-clearempty.php", {
			method: "POST",
			body: submitForm
		})
			.then((res) => res.text())
			.catch((error) => message.error("请求失败: " + error, 2.5))
			.then(function (response) {
				if (response.indexOf("err") === -1) {
					message.success(response, 2.5);
				} else {
					message.error(response, 2.5);
				}
			});
	};
	useEffect(() => {
		var userInfoGetError = false;
		const userid = localStorage.getItem("hw_user_id");
		fetch("//bbs-api.hywiki.org/v2" + "/user-getinfo.php?id=" + userid, {
			method: "GET"
		})
			.then((res) => res.json())
			.catch((error) => {message.error("请求错误: " + error, 2.5); userInfoGetError = true;})
			.then(function (response) {
				if (!userInfoGetError) {
					setInfo(response);
					setRealnamed(response.realname != "");
					form.setFieldsValue({"realname": response.realname, "background": response.settings.background});
					message.success("获取用户信息成功！", 1);
				}
			});
	}, [updateFlag]);

	return (
		<Wrap BrLv1="用户中心" BrLv2="设置与管理">
			<Typography>
				<Title level={2}>设置与管理</Title>
				<Paragraph>Settings</Paragraph>
				<Divider />
				<Title level={3}>&nbsp;&nbsp;个人设置</Title>
				<Form
					{...layout}
					layout="horizontal"
					ref={formRef}
					form={form}
					onFinish={finishTable}
				>
					<Form.Item
						label="主站壁纸"
						name="background"
						rules={[{ required: true }]}
					>
						<Radio.Group>
							<Radio.Button value="huayu">华育风光</Radio.Button>
							<Radio.Button value="bing">必应壁纸</Radio.Button>
							<Radio.Button value="mc">MC壁纸</Radio.Button>
							<Radio.Button value="cat">猫娘壁纸</Radio.Button>
							<Radio.Button value="acg">二次元壁纸</Radio.Button>
							<Radio.Button value="blank">空白</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Form.Item name="realname" label="实名认证">
						<Input allowClear style={{width:200}} disabled={realnamed} placeholder="填写后无法更改"/>
      				</Form.Item>
					<Form.Item
						wrapperCol={{
							...layout.wrapperCol,
							offset: 3
						}}
					>
						<Paragraph>实名认证中，请输入<b>五位学号+姓名</b>，用空格分隔。外校友人请输入<b>学校+姓名</b>，用空格分隔。实名信息<b>一经设置无法更改</b>，请谨慎如实填写。</Paragraph>
						<Button type="primary" htmlType="submit">
							更新
						</Button>
					</Form.Item>
				</Form>
				<Divider />

				<Title level={3}>&nbsp;&nbsp;后台管理</Title>
				<Paragraph>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;这些功能仅向论坛管理员开放，其他用户进行操作无效。
				</Paragraph>
				<Paragraph>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;清理空的HYWiki讨论：
					<Button type="primary" onClick={clearEmpty}>
						清理
					</Button>
				</Paragraph>
			</Typography>
		</Wrap>
	);
};

export default UserSet;
