import React, { useEffect } from "react";
import { Button, Result } from "antd";
import Wrap from "./../Wrap";

const LoginResult: React.FC = (props) => {
	const { data } = props.match.params;
	var oadata;
	useEffect(() => {
		oadata = JSON.parse(atob(data));
		localStorage.setItem("hw_user", "Y");
		localStorage.setItem("hw_user_id", oadata.id);
		localStorage.setItem("hw_user_name", oadata.name);
		localStorage.setItem("hw_user_pwd", oadata.pwd);
	}, [data]);
	return (
		<Wrap BrLv1="用户中心" BrLv2="OAuth认证结果">
			<Result
				status={"success"}
				title="登录成功"
				subTitle={"您已经成功使用HYWiki账号" + "登录论坛。"}
				extra={[
					<Button
						type="primary"
						key="list"
						onClick={() => {
							window.location.href = "#/forum/list";
						}}
					>
						前往话题列表
					</Button>,
					<Button
						key="home"
						onClick={() => {
							window.location.href = "#/";
						}}
					>
						回到首页
					</Button>
				]}
			/>
		</Wrap>
	);
};

export default LoginResult;
