import React from "react";
import { Button, Result } from "antd";
import Wrap from "./../Wrap";

const Err404: React.FC = () => (
	<Wrap BrLv1="错误" BrLv2="404">
		<Result
			status="404"
			title="404"
			subTitle="Sorry, the page you visited does not exist."
			extra={
				<Button
					key="home"
					onClick={() => {
						window.location.href = "#/";
					}}
				>
					回到首页
				</Button>
			}
		/>
	</Wrap>
);

export default Err404;
