import React from "react";
//import { hashHistory as History } from "react-router-dom";
//import { PageHeader, Tabs, Button, Statistic, Descriptions, Card } from "antd";
//import "./App.css";
import {
	BulbOutlined,
	CloudOutlined,
	NotificationOutlined,
	UserOutlined,
	HomeOutlined,
	MessageOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, FloatButton, theme, message } from "antd";

const { Header, Content, Sider, Footer, Title } = Layout;

/*
const items1: MenuProps["items"] = ["Huayu Wiki", "Forum"].map((key) => ({
	key,
	label: `${key}`
}));
const listItems = [
	["华育Wiki", "首页", "登录"],
	["论坛", "开始一个话题", "查看所有话题"],
	["云盘", "开发中", "敬请期待"],
	["通知", "开发中", "敬请期待"],
	["用户中心", "开发中", "敬请期待"]
];
const items2: MenuProps["items"] = [
	HomeOutlined,
	BulbOutlined,
	CloudOutlined,
	NotificationOutlined,
	UserOutlined
].map((icon, index) => {
	const key = String(index + 1);

	return {
		key: `sub${key}`,
		icon: React.createElement(icon),
		label: listItems[index][0],

		children: listItems[index].slice(1).map((k, j) => {
			return {
				key: index * 10 + j,
				label: k
			};
		})
		new Array(4).fill(null).map((_, j) => {
			const subKey = index * 4 + j + 1;
			return {
				key: subKey,
				label: `option${subKey}`
			};
		})
	};
});*/
function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type
	};
}
//			    <div width={240} height={60} style={{ backgroundImage: "url(https://bbs.nth.ink/assets/logo-ufohhy7r.png)" }} />
const Wrap: React.FC = (props) => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { children, BrLv1, BrLv2 } = props;

	var ucenterItems;
	if (localStorage.getItem("hw_user") === "Y") {
		ucenterItems = [
			getItem(
				"通知 (0)",
				"/user/notifications",
				<NotificationOutlined />
			),
			getItem("设置中心", "/user/settings"),
			getItem(
				"个人主页",
				"/user/i/" +
					(localStorage.getItem("hw_user_id")
						? localStorage.getItem("hw_user_id")
						: "0")
			),
			getItem("登出", "/user/logout")
		]
	} else {
		ucenterItems = [
			getItem("登录", "/user/login")
		]
	}
	const items = [
		getItem("HYWiki论坛", "/", <HomeOutlined />),
		getItem("论坛", "/forum", <BulbOutlined />, [
			getItem("开始一个话题", "/forum/start"),
			getItem("查看所有话题", "/forum/list")
		]),
		getItem("议事厅", "/vote", <MessageOutlined />, [
			getItem("账号与权限申请", "/vote/account"),
			getItem("投票选举", "/vote/election")
		]),
		getItem(
			localStorage.getItem("hw_user") === "Y"
				? localStorage.getItem("hw_user_name")
				: "用户中心",
			"/user",
			<UserOutlined />,
			ucenterItems
		)
	];

	const handleClick = (e) => {
		if (e.key === "/user/logout") {
			localStorage.setItem("hw_user", "N");
			localStorage.removeItem("hw_user_id");
			localStorage.removeItem("hw_user_name");
			localStorage.removeItem("hw_user_pwd");
			message.info("您已退出登录", 2.5);
			setTimeout(function () {
				window.location.hash = "/user/login";
			}, 1000);
		} else {
			window.location.hash = e.key;
		}
	};
	
	return (
		<Layout>
			<Header className="header">
				<div>
					<img
						src="/assets/logo.png"
						style={{
							marginTop: 12,
							marginLeft: -16,
							width: 320,
							height: 40
						}}
					/>
				</div>
			</Header>
			<Layout>
				<Sider
					breakpoint="lg"
					collapsedWidth="0"
					onBreakpoint={(broken) => {
						console.log(broken);
					}}
					onCollapse={(collapsed, type) => {
						console.log(collapsed, type);
					}}
				>
					<div className="logo" />
					<Menu
						mode="inline"
						defaultSelectedKeys={[props.location.pathname]}
						defaultOpenKeys={["/forum"]}
						style={{ height: "100%", borderRight: 0 }}
						items={items}
						onClick={handleClick}
					/>
				</Sider>
				{children}
			</Layout>
			<FloatButton.BackTop />
		</Layout>
	);
};

export default Wrap;
