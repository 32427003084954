import React, { createRef, useState, useEffect } from "react";
import {
	Typography,
	Divider,
	theme,
	message,
	Descriptions,
	Timeline
} from "antd";
import {
	EyeInvisibleOutlined,
	EyeTwoTone,
	UserOutlined,
	LockOutlined
} from "@ant-design/icons";
import Wrap from "./../Wrap";

const layout = {
	labelCol: {
		span: 3
	},
	wrapperCol: {
		span: 14
	}
};

const Login: React.FC = (props) => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	var formRef = React.createRef();
	const { userid } = props.match.params;
	const [info, setInfo] = useState({
		id: 0,
		name: "",
		realname: "",
		email: "",
		description: "",
		birthday: "YYYY-mm-dd",
		register: "2022-12-15 12:00",
		action: [],
		settings: {"background":"huayu"}
	});

	useEffect(() => {
		var userInfoGetError = false;
		fetch("//bbs-api.hywiki.org/v2" + "/user-getinfo.php?id=" + userid, {
			method: "GET"
		})
			.then((res) => res.json())
			.catch((error) => {message.error("请求错误: " + error, 2.5); userInfoGetError = true;})
			.then(function (response) {
				if (!userInfoGetError) {
					setInfo(response);
					message.success("获取用户信息成功！", 1);
				}
			});
			/*

			const uInfoFetch = await fetch("//bbs-api.hywiki.org/v2" + "/user-getinfo.php?id=" + userid, {
				method: "GET"
			});
			if (uInfoFetch.status >= 200 && uInfoFetch.status < 400) {
				const uInfoJson = await uInfoFetch.json();
				setInfo(uInfoJson);
				message.success("获取用户信息成功！", 1);
			} else {
				message.error("请求错误: " + error, 2.5);
			}
			console.log(uInfoFetch)
			*/
	}, [userid]);

	return (
		<Wrap BrLv1="用户中心" BrLv2={"用户信息: " + info.name}>
			<Typography>
				<Title level={2}>用户: {info.name}</Title>
				<Paragraph>ID: {userid}</Paragraph>
				<Divider />
				<Descriptions title="用户信息">
					<Descriptions.Item label="用户名">
						{info.name}
					</Descriptions.Item>
					<Descriptions.Item label="用户ID">
						{info.id}
					</Descriptions.Item>
					<Descriptions.Item label="邮箱 / E-mail">
						{info.email}
					</Descriptions.Item>
					<Descriptions.Item label="生日 / Birthday">
						{info.birthday}
					</Descriptions.Item>
					<Descriptions.Item
						label="注册时间 / Register time"
						span={2}
					>
						{info.register}
					</Descriptions.Item>
					<Descriptions.Item label="个人简介 / Description" span={3}>
						{info.description}
					</Descriptions.Item>
					<Descriptions.Item label="近期活动">
						<Timeline>
							{info.action.map((act, index) => {
								return (
									<Timeline.Item key={index}>
										{act.t + " " + act.c}
									</Timeline.Item>
								);
							})}
						</Timeline>
					</Descriptions.Item>
				</Descriptions>
			</Typography>
		</Wrap>
	);
};

export default Login;
