import React, { createRef, useState, useEffect } from "react";
import {
	Typography,
	Divider,
	theme,
	List,
	Skeleton,
	Button,
	message
} from "antd";
import Wrap from "./../../Wrap";

const VoteAccount: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;

	const [initLoading, setInitLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState([]);
	const [offset, setOffset] = useState(0);
	useEffect(() => {
		refreshList();
	}, []);
	const refreshList = () => {
		fetch("//bbs-api.hywiki.org/v2" + "/vote/account/getlist.php")
			.then((res) => res.json())
			.then((res) => {
				setInitLoading(false);
				setList(res);
			});
	};
	const listAction = (aid, action) => {
		let submitForm = new FormData();
		submitForm.append("id", localStorage.getItem("hw_user_id"));
		submitForm.append("pwd", localStorage.getItem("hw_user_pwd"));
		submitForm.append("aid", aid);
		submitForm.append("action", action);

		fetch("//bbs-api.hywiki.org/v2" + "/vote/account/approve.php", {
			method: "POST",
			body: submitForm
		})
			.then((res) => res.text())
			.catch((error) => message.error("操作失败: " + error, 2.5))
			.then(function (response) {
				if (response.indexOf("err") === -1) {
					message.success(response, 2.5);
					refreshList();
				} else {
					message.error(response, 2.5);
				}
			});
	};
	const funcHandler = (status, aid) => {
		if (status === 1) return ["已批准"];
		if (status === -1) return ["已驳回"];
		return [
			<Button
				type="primary"
				onClick={() => {
					listAction(aid, "approve");
				}}
			>
				批准
			</Button>,
			<Button
				onClick={() => {
					listAction(aid, "reject");
				}}
			>
				驳回
			</Button>
		];
	};

	return (
		<Wrap BrLv1="议事厅" BrLv2="账号与权限申请">
			<Typography>
				<Title level={2}>账号与权限申请</Title>
				<Paragraph>当前已加载 {list.length} 个申请</Paragraph>
				<Button
					type="primary"
					onClick={() => {
						window.location.hash = "/vote/account/apply-account";
					}}
				>
					申请账号
				</Button>
				&nbsp;&nbsp;
				<Button
					onClick={() => {
						window.location.hash = "/vote/account/apply-editor";
					}}
				>
					申请编辑权限
				</Button>
			</Typography>
			<Divider />
			<List
				className="apply-list"
				loading={initLoading}
				itemLayout="horizontal"
				dataSource={list}
				responsive="lg"
				renderItem={(item) => (
					<List.Item actions={funcHandler(item.status, item.id)}>
						<Skeleton title={false} loading={item.loading} active>
							<List.Item.Meta
								title={
									item.account +
									"的 " +
									(item.type === 10
										? "账号创建申请"
										: "编辑权限申请")
								}
								description={"提交的学号姓名: " + item.xhxm}
							/>
							<div style={{ whiteSpace: "pre-wrap" }}>
								{item.time}
							</div>
						</Skeleton>
					</List.Item>
				)}
			/>
		</Wrap>
	);
};

export default VoteAccount;
