import React from "react";
import { Typography, Divider, theme, List } from "antd";
import Wrap from "./Wrap";

const App: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;

	const notice = [
		"提示: 本站所有需要提交内容的功能(除账号申请外)均需登录后才可使用，请您事先登录好避免编辑的内容无法提交，前功尽弃！",
		"2023-07-09 开发进度: “议事厅”功能中账号申请、权限申请功能已完工",
		"2023-06-19 开发进度: “议事厅”模块开发启动",
		"2023-01-28 重要提示: 修复了一个重大安全问题，请按Ctrl+F5刷新页面，否则会无法登录。",
		"2023-01-28 开发进度: 基本实现移动端适配",
		"2023-01-28 公告: 从今起在个人资料页面增加“最近活动”功能，(之前您在本站的活动无法展示，请谅解)",
		"2023-01-27 公告: 从现在起，不定期清理没有任何回复的话题",
		"2023-01-26 开发进度: 实现OAuth登录，通过OAuth 1.0a协议可直接用HYWiki账号登录本站",
		"2023-01-25 开发进度: 已在Wiki主站实现了快速从词条创建在本站的讨论",
		"2022-12-19 项目基本完工",
		"2021-11-04 项目启动"
	];

	return (
		<Wrap BrLv1="首页" BrLv2="首页">
			<Typography>
				<Title level={2}>HYWiki论坛</Title>
				<Paragraph>Welcome to HYWiki BBS!</Paragraph>
				<Divider />
				<Paragraph>
					本站是
					<a href="https://hywiki.org/" target="_blank">
						HYWiki
					</a>
					的附属项目，旨在提供一个更加适合本站用户群体，比Wiki自带讨论页更易用的词条讨论工具。
				</Paragraph>
				<Paragraph>
					同时，为方便HYWiki用户使用，本站与主站深度集成的同时还增加了更换Wiki主站壁纸与申请Wiki主站账号的功能。
				</Paragraph>
				<List
					size="small"
					header={
						<div>
							<b>开发进展与公告</b>
						</div>
					}
					bordered
					dataSource={notice}
					renderItem={(item) => <List.Item>{item}</List.Item>}
				/>
				<Paragraph>
					<br />
					友情链接:{" "}
					<a href="https://hywiki.org/" target="_blank">
						HYWiki主站
					</a>
				</Paragraph>
			</Typography>
		</Wrap>
	);
};

export default App;
