import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import {withRouter} from 'react-router-dom';
import "./index.css";
import App from "./App";
import WrapMenu from "./WrapMenu";
import ForumStart from "./forum/start";
import ForumList from "./forum/list";
import ForumView from "./forum/view";
import ForumViewNM from "./forum/view-nomce";
import Login from "./user/login";
import LoginResult from "./user/login-oauth-result";
import UserInfo from "./user/info";
import UserSet from "./user/settings";
import VoteAccountApplyAccount from "./vote/account/apply-account";
import VoteAccountApplyEditor from "./vote/account/apply-editor";
import VoteAccount from "./vote/account/index";
import Err404 from "./error/404";
import reportWebVitals from "./reportWebVitals";

const WrapMenuWithRouterProps = withRouter(props => <WrapMenu {...props}/>);

ReactDOM.render(
	<React.StrictMode>
		<Router>
		    <WrapMenuWithRouterProps>
				<Switch>
					<Route path="/" exact component={App} />
					<Route path="/forum/start" exact component={ForumStart} />
					<Route path="/forum/list" exact component={ForumList} />
					<Route path="/forum/view/:topicid" component={ForumView} />
					<Route
						path="/forum/view-classic/:topicid"
						component={ForumViewNM}
					/>
					<Route path="/vote/account" exact component={VoteAccount} />
					<Route
						path="/vote/account/apply-editor"
						exact
						component={VoteAccountApplyEditor}
					/>
					<Route
						path="/vote/account/apply-account"
						exact
						component={VoteAccountApplyAccount}
					/>
					<Route path="/user/login" exact component={Login} />
					<Route path="/user/settings" exact component={UserSet} />
					<Route
						path="/user/login-oauth-result/:data"
						exact
						component={LoginResult}
					/>
					<Route path="/user/i/:userid" component={UserInfo} />
					<Route path="*" component={Err404} />
				</Switch>
		    </WrapMenuWithRouterProps>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
