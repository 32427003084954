import React, { createRef } from "react";
import {
	Typography,
	Divider,
	theme,
	Form,
	Radio,
	Input,
	Button,
	message
} from "antd";
import Wrap from "./../Wrap";

const layout = {
	labelCol: {
		span: 3
	},
	wrapperCol: {
		span: 14
	}
};

const ForumStart: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	var formRef = React.createRef();
	const [messageApi, contextHolder] = message.useMessage();
	const finishTable = (event) => {
		formRef.current
			.validateFields()
			.then((value) => {
				let submitForm = new FormData();
				submitForm.append("id", localStorage.getItem("hw_user_id"));
				submitForm.append("pwd", localStorage.getItem("hw_user_pwd"));
				submitForm.append("topic", value["topic"]);
				submitForm.append("category", value["category"]);
				submitForm.append("content", value["content"]);

				fetch("//bbs-api.hywiki.org/v2" + "/forum/topic-create.php", {
					method: "POST",
					body: submitForm
				})
					.then((res) => res.text())
					.catch((error) => message.error("发布失败: " + error, 2.5))
					.then(function (response) {
						if (response.indexOf("err") === -1) {
							message.success(response, 2.5);
							setTimeout(function () {
								window.location.hash = "/forum/list";
							}, 2000);
						} else {
							message.error(response, 2.5);
						}
					});
			})
			.catch((err) => {
				alert("数据校验失败: " + err);
			});
	};

	return (
		<Wrap BrLv1="论坛" BrLv2="创建话题">
			<Typography>
				<Title level={2}>开始一个话题</Title>
				<Paragraph>Start a topic</Paragraph>
				<Divider />
				<Form
					{...layout}
					layout="horizontal"
					ref={formRef}
					onFinish={finishTable}
				>
					<Form.Item
						label="主题"
						name="topic"
						rules={[
							{
								required: true
							}
						]}
					>
						<Input allowClear />
					</Form.Item>
					<Form.Item
						label="话题分类"
						name="category"
						rules={[{ required: true }]}
					>
						<Radio.Group>
							<Radio.Button value="school">校园生活</Radio.Button>
							<Radio.Button value="tech">科技数码</Radio.Button>
							<Radio.Button value="site">论坛站务</Radio.Button>
							<Radio.Button value="water">灌水区</Radio.Button>
							<Radio.Button value="others">其它</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Form.Item name="content" label="主题内容">
						<Input.TextArea allowClear rows={5} />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							...layout.wrapperCol,
							offset: 3
						}}
					>
						<Button type="primary" htmlType="submit">
							发布
						</Button>
					</Form.Item>
				</Form>
			</Typography>
		</Wrap>
	);
};

export default ForumStart;
