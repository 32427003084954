import React, { createRef, useState, useEffect, useRef } from "react";
import {
	Typography,
	Divider,
	theme,
	Form,
	Radio,
	Input,
	Button,
	message,
	Avatar,
	List,
	Space
} from "antd";
import { LikeOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import Wrap from "./../Wrap";

const layout = {
	labelCol: {
		span: 3
	},
	wrapperCol: {
		span: 14
	}
};

const ForumView: React.FC = (props) => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	const editorRef = useRef(null);
	var formRef = React.createRef();
	const { topicid } = props.match.params;

	const [data, setData] = useState({
		id: 0,
		topic: "",
		content: [],
		updtime: "",
		category: "",
		manager: 0
	});
	const [loading, setLoading] = useState(true);
	//const [userData, setUserData] = useState(new Map());
	
	const [loginNotify, setLoginNotify] = useState(<></>);
	useEffect(() => {
	    if (localStorage.getItem("hw_user") !== "Y") {
	        setLoginNotify(<>&nbsp;&nbsp;<b>提示: 您尚未登录，输入的内容无法提交。</b>您可以 <Button onClick={()=>{window.open('/#/user/login', 'newwindow', 'height=400,width=800,top=100,left=100,toolbar=no,menubar=no,resizable=no,location=no');return false;}}>点击这里登录</Button> 。完成登录后请关闭弹出的登录窗口，<b>不要刷新页面</b>，直接点击上面的按钮即可提交。<br/></>);
	        //如果没有反应，可以打开一个新的HYWiki Forum页面，选择左侧边栏-用户中心-登录。完成登录后不要刷新页面，直接点击下面的按钮即可提交。
	    }
	}, []);

	function loadTopic(cap) {
		fetch(
			"//bbs-api.hywiki.org/v2" + "/forum/topic-getlist.php?id=" + topicid
		)
			.then((res) => res.json())
			.catch((error) => {
				message.error("加载话题失败: " + error, 2.5);
				setData({
					id: topicid,
					topic: "",
					content: [],
					brief: "",
					updtime: "",
					category: "hywiki",
					manager: 1,
					status: 1
				});
				setTimeout(function () {
					window.location.hash = "/forum/list";
				}, 2500);
			})
			.then((response) => {
				/*
				let tempuserData = new Map();
				response.content.forEach((e) => {
					fetch("//bbs-api.hywiki.org/v2" + "/user-getinfo.php?id=" + e.u)
			        .then((res) => res.json())
			        .catch((error) => message.error("加载用户信息失败: " + error, 2.5))
			        .then((resp) => {
			            e.uiname = resp.name;
			        })
				})
			            console.log(response)*/
				setLoading(false);
				if (response) {
					setData(response);
					//setUserData(tempuserData);
					if (cap) message.success("话题加载成功", 2.5);
				}
			});
	}

	useEffect(() => {
		loadTopic(true);
	}, [topicid]);
	const IconText = ({ icon, text }) => (
		<Space>
			{React.createElement(icon)}
			{text}
		</Space>
	);
	const finishTable = (event) => {
		if (editorRef.current) {
			let content = editorRef.current.getContent();
			if (content !== undefined && content !== "") {
				let submitForm = new FormData();
				submitForm.append("tid", topicid);
				submitForm.append("id", localStorage.getItem("hw_user_id"));
				submitForm.append("pwd", localStorage.getItem("hw_user_pwd"));
				submitForm.append("content", content);

				fetch("//bbs-api.hywiki.org/v2" + "/forum/topic-continue.php", {
					method: "POST",
					body: submitForm
				})
					.then((res) => res.text())
					.catch((error) => message.error("发布失败: " + error, 2.5))
					.then(function (response) {
						if (response.indexOf("err") === -1) {
							//document.querySelector("#continueTextarea").value = "";
							message.success(response, 2.5);
							loadTopic(false);
							editorRef.current.setContent("");
						} else {
							message.error(response);
						}
					});
			}
		}
	};

	const eToC = (e) => {
		if (e == "school") return "校园生活区";
		if (e == "tech") return "科技数码区";
		if (e == "site") return "站务区";
		if (e == "water") return "灌水区";
		if (e == "hywiki") return "维基讨论";
		if (e == "others") return "其他";
		return e;
	};

	return (
		<Wrap BrLv1="论坛" BrLv2={"话题: " + data.topic}>
			<Typography>
				<Title level={2}>{data.topic}</Title>
				<Paragraph>
					{"ID: " +
						data.id +
						" / 分类: " +
						eToC(data.category) +
						" / 创建时间: " +
						(data.content.length > 0 ? data.content[0].t : "") +
						" / 更新时间: " +
						data.updtime +
						" / 楼层数: " +
						data.content.length}
				</Paragraph>
				<Divider />
				<List
					itemLayout="vertical"
					size="large"
					loading={loading}
					pagination={{
						onChange: (page) => {
							console.log(page);
						},
						showSizeChanger: true,
						defaultPageSize: 30
						//defaultCurrent: (Math.floor(data.content.length / 20) + 1)
					}}
					dataSource={data.content}
					footer={
						<div>
							<b>话题:</b> {data.topic} / <b>楼数:</b>{" "}
							{data.content.length} / <b>状态:</b> 开放
						</div>
					}
					renderItem={(item) => (
						<List.Item
							key={item.t}
							actions={[
								<IconText
									icon={LikeOutlined}
									text="点赞 (开发中)"
									key="list-vertical-like-o"
								/>
							]}
						>
							<List.Item.Meta
								title={
									<a href={"#/user/i/" + item.u}>
										{(item.i === 1
											? "楼主: "
											: "" + item.i + "楼: ") + item.un}
									</a>
								}
								description={item.t}
							/>
							<div
								dangerouslySetInnerHTML={{ __html: item.c }}
							></div>
						</List.Item>
					)}
				/>
				<Form
					{...layout}
					layout="horizontal"
					ref={formRef}
					onFinish={finishTable}
				>
					<Editor
						onInit={(evt, editor) => (editorRef.current = editor)}
						initialValue=""
						init={{
							height: 300,
							menubar: false,
							branding: false,
							/*plugins: [
             'advlist autolink lists link image charmap print preview',
             'searchreplace visualblocks codesample fullscreen',
             'insertdatetime media table paste code help wordcount emoticons'
           ],*/
							plugins: [
								"lists link charmap preview",
								"searchreplace visualblocks fullscreen",
								"paste wordcount emoticons"
							],
							toolbar:
								"paste undo redo | formatselect | " +
								"bold italic backcolor | searchreplace | bullist numlist outdent indent | " +
								"removeformat | link emoticons charmap | wordcount | preview fullscreen",
							content_style:
								"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
						}}
						tinymceScriptSrc="https://cdn.staticfile.net/tinymce/5.10.9/tinymce.min.js"
					/>
					<br />
					<Form.Item
						wrapperCol={{
							...layout.wrapperCol
						}}
					>
						<Button type="primary" htmlType="submit">
							跟帖
						</Button>
					</Form.Item>
					<Paragraph>
						{loginNotify}&nbsp;&nbsp;富文本编辑器首次加载较慢，大约需要10s左右，请耐心等待。如果加载非常缓慢或者您用不习惯，可以
						<a href={"#/forum/view-classic/" + topicid}>
							点此换用传统编辑器
						</a>
						（已编辑的内容会丢失）。
					</Paragraph>
				</Form>
			</Typography>
		</Wrap>
	);
};

export default ForumView;
