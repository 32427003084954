import React, { createRef } from "react";
import {
	Typography,
	Divider,
	theme,
	Form,
	Radio,
	Input,
	Button,
	message
} from "antd";
import {
	EyeInvisibleOutlined,
	EyeTwoTone,
	UserOutlined,
	LockOutlined
} from "@ant-design/icons";
import Wrap from "./../Wrap";

const layout = {
	labelCol: {
		span: 3
	},
	wrapperCol: {
		span: 14
	}
};

const Login: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	var formRef = React.createRef();
	const [messageApi, contextHolder] = message.useMessage();
	const finishTable = (event) => {
		formRef.current
			.validateFields()
			.then((value) => {
				let submitForm = new FormData();
				submitForm.append("username", value["username"]);
				submitForm.append("password", value["password"]);

				fetch("//bbs-api.hywiki.org/v2" + "/user-login.php", {
					method: "POST",
					body: submitForm
				})
					.then((res) => res.text())
					.catch((error) => message.error("请求错误: " + error, 2.5))
					.then(function (response) {
						if (response.indexOf("err") === -1) {
							localStorage.setItem("hw_user", "Y");
							localStorage.setItem("hw_user_id", response);
							localStorage.setItem(
								"hw_user_name",
								value["username"]
							);
							localStorage.setItem(
								"hw_user_pwd",
								value["password"]
							);
							message.success("登录成功! 2秒后返回论坛首页", 2.5);
							setTimeout(function () {
								window.location.hash = "/forum/list";
							}, 2000);
						} else {
							message.error("登录失败: " + response, 2.5);
						}
					});
			})
			.catch((err) => {
				alert("数据校验失败: " + err);
			});
	};

	return (
		<Wrap BrLv1="用户中心" BrLv2="登录">
			<Typography>
				<Title level={2}>登录您的账号</Title>
				<Paragraph>
					如在登录中遇到问题，请联系23564（QQ:2673895667）。
				</Paragraph>
				<Divider />
				<Form
					{...layout}
					layout="horizontal"
					ref={formRef}
					onFinish={finishTable}
				>
					<Form.Item
						label="用户名"
						name="username"
						rules={[
							{
								required: true
							}
						]}
					>
						<Input
							prefix={
								<UserOutlined className="site-form-item-icon" />
							}
							placeholder="Username"
						/>
					</Form.Item>
					<Form.Item
						label="密码"
						name="password"
						rules={[
							{
								required: true
							}
						]}
					>
						<Input.Password
							prefix={
								<LockOutlined className="site-form-item-icon" />
							}
							placeholder="Input password"
							iconRender={(visible) =>
								visible ? (
									<EyeTwoTone />
								) : (
									<EyeInvisibleOutlined />
								)
							}
						/>
					</Form.Item>
					<Form.Item
						wrapperCol={{
							...layout.wrapperCol,
							offset: 3
						}}
					>
						<Button type="primary" htmlType="submit">
							登录
						</Button>
					</Form.Item>
					<Form.Item label="您也可以:">
						<Button
							onClick={() => {
								window.location.href =
									"https://bbs-api.hywiki.org/v2/user-oauth.php?action=authorize";
							}}
						>
							用HYWiki账号登录
						</Button>
					</Form.Item>
				</Form>
			</Typography>
		</Wrap>
	);
};

export default Login;
