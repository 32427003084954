import React, { createRef } from "react";
import {
	Typography,
	Divider,
	theme,
	Form,
	Radio,
	Input,
	Button,
	message
} from "antd";
import Wrap from "./../../Wrap";

const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 14
	}
};

const VoteAccountApplyAccount: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	var formRef = React.createRef();
	const [messageApi, contextHolder] = message.useMessage();
	const finishTable = (event) => {
		formRef.current
			.validateFields()
			.then((value) => {
				let submitForm = new FormData();
				submitForm.append("account", value["account"]);
				submitForm.append("password", value["password"]);
				submitForm.append("xhxm", value["xhxm"]);

				fetch(
					"//bbs-api.hywiki.org/v2" +
						"/vote/account/apply-account.php",
					{
						method: "POST",
						body: submitForm
					}
				)
					.then((res) => res.text())
					.catch((error) => message.error("申请失败: " + error, 2.5))
					.then(function (response) {
						if (response.indexOf("err") === -1) {
							message.success(response, 2.5);
							setTimeout(function () {
								window.location.hash = "/vote/account";
							}, 2000);
						} else {
							message.error(response, 2.5);
						}
					});
			})
			.catch((err) => {
				alert("数据校验失败: " + err);
			});
	};

	return (
		<Wrap BrLv1="议事厅" BrLv2="申请账号">
			<Typography>
				<Title level={2}>申请账号</Title>
				<Paragraph>
					获得HYWiki账号即可浏览站内的绝大部分内容。
					<br />
					建议您在申请前事先与HYWiki管理员联系以提高成功率。当申请被审核通过后，您的帐户将可用。
					<br />
					<b>
						建议您在首次登录后再更改一次密码以避免可能的安全隐患。
					</b>
				</Paragraph>
				<Divider />
				<Form
					{...layout}
					layout="horizontal"
					ref={formRef}
					onFinish={finishTable}
				>
					<b>
						请确保您申请的账号用户名不存在，否则无法注册。不要在用户名中包含任何英文符号和超过一个的连续空格。
					</b>
					建议使用包含实际意义的用户名来提升审核通过的概率。
					<Form.Item
						label="申请账号用户名"
						name="account"
						rules={[
							{
								required: true
							}
						]}
					>
						<Input allowClear />
					</Form.Item>
					<b>
						密码要求：不少于8位，数字字母混合，不在最常使用的密码列表中。不符合上述要求的密码将无法注册成功。
					</b>
					<Form.Item
						label="申请账号的密码"
						name="password"
						rules={[
							{
								required: true
							}
						]}
					>
						<Input allowClear />
					</Form.Item>
					<b>学号和姓名信息会被用于审核且会被公开记录。</b>
					<Form.Item
						label="真实学号和姓名"
						name="xhxm"
						rules={[
							{
								required: true
							}
						]}
					>
						<Input allowClear />
					</Form.Item>
					<Paragraph>
						<b>本页提交的申请不是立即通过，您可能需要等待1-5天。</b>
						管理员精力有限，请耐心等待，<b>重复提交的申请不予受理</b>。
					</Paragraph>
					<Paragraph>
						<b>确保您已阅读并同意</b>
						<a
							href="https://hywiki.org/wiki/HYWiki:%E4%BD%BF%E7%94%A8%E5%89%8D%E5%BF%85%E8%AF%BB"
							target="_blank"
						>
							HYWiki:使用前必读
						</a>
						、
						<a
							href="https://hywiki.org/wiki/HYWiki:%E5%85%8D%E8%B4%A3%E5%A3%B0%E6%98%8E"
							target="_blank"
						>
							HYWiki:免责声明
						</a>
						、
						<a
							href="https://hywiki.org/wiki/HYWiki:%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96"
							target="_blank"
						>
							HYWiki:隐私政策
						</a>
						。
					</Paragraph>
					<Form.Item
						wrapperCol={{
							...layout.wrapperCol,
							offset: 3
						}}
					>
						<Button type="primary" htmlType="submit">
							提交申请
						</Button>
					</Form.Item>
				</Form>
			</Typography>
		</Wrap>
	);
};

export default VoteAccountApplyAccount;
