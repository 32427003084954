import React, { createRef } from "react";
import {
	Typography,
	Divider,
	theme,
	Form,
	Radio,
	Input,
	Button,
	message
} from "antd";
import Wrap from "./../../Wrap";

const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 14
	}
};

const VoteAccountApplyEditor: React.FC = () => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { Title, Paragraph } = Typography;
	var formRef = React.createRef();
	const [messageApi, contextHolder] = message.useMessage();
	const finishTable = (event) => {
		formRef.current
			.validateFields()
			.then((value) => {
				let submitForm = new FormData();
				submitForm.append("id", localStorage.getItem("hw_user_id"));
				submitForm.append("pwd", localStorage.getItem("hw_user_pwd"));
				submitForm.append("xhxm", value["xhxm"]);
				submitForm.append(
					"account",
					localStorage.getItem("hw_user_name")
				);

				fetch(
					"//bbs-api.hywiki.org/v2" +
						"/vote/account/apply-editor.php",
					{
						method: "POST",
						body: submitForm
					}
				)
					.then((res) => res.text())
					.catch((error) => message.error("申请失败: " + error, 2.5))
					.then(function (response) {
						if (response.indexOf("err") === -1) {
							message.success(response, 2.5);
							setTimeout(function () {
								window.location.hash = "/vote/account";
							}, 2000);
						} else {
							message.error(response, 2.5);
						}
					});
			})
			.catch((err) => {
				alert("数据校验失败: " + err);
			});
	};

	return (
		<Wrap BrLv1="议事厅" BrLv2="申请编辑者权限">
			<Typography>
				<Title level={2}>申请编辑者权限</Title>
				<Paragraph>
					获得编辑者(Editor)权限即可对HYWiki的内容做出编辑。详见
					<a
						href="https://hywiki.org/wiki/HYWiki:%E5%8F%97%E8%AE%A4%E8%AF%81%E7%9A%84%E7%BC%96%E8%BE%91%E8%80%85"
						target="_blank"
					>
						此链接
					</a>
					<br />
					建议您在申请前事先与HYWiki管理员联系以提高成功率。您的申请审核通过后，您会自动被添加到Editor用户组。
					<br />
					管理员精力有限，请耐心等待，重复提交的申请不予受理。
					<br />
					<b>提示: 申请前请先登录!</b>
				</Paragraph>
				<Divider />
				<Form
					{...layout}
					layout="horizontal"
					ref={formRef}
					onFinish={finishTable}
				>
					<Form.Item
						label="真实学号和姓名"
						name="xhxm"
						rules={[
							{
								required: true
							}
						]}
					>
						<Input allowClear />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							...layout.wrapperCol,
							offset: 3
						}}
					>
						<Button type="primary" htmlType="submit">
							提交
						</Button>
					</Form.Item>
				</Form>
			</Typography>
		</Wrap>
	);
};

export default VoteAccountApplyEditor;
