import React from "react";
//import { hashHistory as History } from "react-router-dom";
//import { PageHeader, Tabs, Button, Statistic, Descriptions, Card } from "antd";
//import "./App.css";
import { HomeOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Breadcrumb, Layout, theme, message } from "antd";

const { Header, Content, Sider, Footer, Title } = Layout;

const Wrap: React.FC = (props) => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { children, BrLv1, BrLv2 } = props;

	return (
		<Layout>
			<Breadcrumb style={{ margin: "16px 16px" }}>
				<Breadcrumb.Item>
					<HomeOutlined /> Home
				</Breadcrumb.Item>
				<Breadcrumb.Item>{BrLv1}</Breadcrumb.Item>
				<Breadcrumb.Item>{BrLv2}</Breadcrumb.Item>
			</Breadcrumb>
			<Content
				style={{
					padding: 24,
					margin: "0 16px",
					minHeight: 280,
					background: colorBgContainer
				}}
			>
				{children}
			</Content>
			<Footer style={{ textAlign: "center" }}>
				HYWiki论坛由
				<a href="https://blog.nth.ink" target="_blank">
					Ericnth
				</a>
				制作。
				<br />
				HYWiki论坛是
				<a href="https://hywiki.org" target="_blank">
					华育维基
				</a>
				计划的重要组成部分。
				<br />
				沪ICP备2020025694号-2
			</Footer>
		</Layout>
	);
};

export default Wrap;
